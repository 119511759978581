import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import Otherservice from "../components/otherservice";
import { IoArrowUpOutline } from "react-icons/io5";
import { AnimatePresence } from "framer-motion";
import Form from "../components/form";
import MetaTag from "../components/metaTag";
import { variantPages } from "../animations/pageTransition";
import { motion } from "framer-motion";
import { springcleaningSEO } from "../components/data/seo";
import {
  container,
  preview,
  stock,
  option,
  price,
  list,
  background,
  title,
  description,
  vector,
  otherservices,
  btn,
} from "../styles/pages/service.module.scss";

function Springcleaning() {
  const [openForm, setOpenForm] = useState(false);

  return (
    <>
      <MetaTag data={springcleaningSEO} />
      <motion.section
        variants={variantPages}
        initial="initial"
        exit="exit"
        animate="animate"
      >
        <div className={container}>
          <div className={preview}>
            <h2 className={title}>Генеральная уборка</h2>
            <p className={description}>от 4500₽</p>
            <StaticImage
              src="../images/services/4.jpg"
              alt="обложка для Генеральной уборки"
              className={background}
            />
          </div>
          <div className={stock}>
            <h3 className={title}>Закажите клининг по акции!</h3>
            <p>
              При заказе любого типа помещения - скидка до 30% на услуги
              химчистки мягкой мебели
            </p>
            <button className={btn} onClick={() => setOpenForm(true)}>
              Заказать уборку <IoArrowUpOutline />
            </button>
            <img src="/cartvector/2.svg" alt="фон вектор" className={vector} />
          </div>
          <div
            className={option}
            style={{
              background: "linear-gradient(180deg, #000576 0%, #D15842 100%)",
            }}
          >
            <h2 className={title}>Что входит:</h2>
            <p className={description}>
              Дополнительные позиции обсуждаются индивидуально
            </p>
            <p className={list}>
              - Сухая и влажная уборка плинтусов
              <br />
              - Сухая чистка ковров
              <br />
              - Очистка всех зеркал и стеклянных поверхностей, всей бытовой
              техники
              <br />
              - Мытье всех осветительных приборов
              <br />
              - Очистка и дезинфекция дверных ручек и всех выключателей, всей
              сантехники и смесителей
              <br />
              - Очистка столешницы и наружных фасадов кухни
              <br />
              - Очистка раковины и плиты от жира и налета
              <br />
              - Очистка полотенцесушителей, вентиляционных решеток
              <br />- Мытье и обработка паром плитки
            </p>
          </div>
          <div
            className={price}
            style={{
              background:
                "linear-gradient(3.2deg, #4449C5 -22.42%, #197BBD 100%)",
            }}
          >
            <h2 className={title}>Прайс</h2>
            <p className={description}>
              Для расчета более точной цены, оставьте заявку
            </p>
            <p className={list}>
              45 кв.м / от 4500₽
              <br />
              65 кв.м / от 6500₽
              <br />
              85 кв.м / от 8500₽
              <br />
              125 кв.м - 160 кв.м / от 12500₽
              <br />
              более 160 кв.м / индивидуально
            </p>
            <img src="/cartvector/1.svg" alt="фон вектор" className={vector} />
          </div>
        </div>
        <h3 className={otherservices}>Прочие услуги:</h3>
        <Otherservice hide={3} />
        <AnimatePresence>
          {openForm && (
            <Form
              service="генеральную уборку"
              closeForm={() => setOpenForm(false)}
            />
          )}
        </AnimatePresence>
      </motion.section>
    </>
  );
}

export default Springcleaning;
